import(/* webpackMode: "eager", webpackExports: ["ProgressProvider"] */ "/learn-web/features/common/containers/ProgressProvider/ProgressProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/containers/ToastProvider/ToastProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/navigation/containers/NavigationProvider/NavigationProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/playground/containers/GlobalPlaygroundProvider/GlobalPlaygroundProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/learn-web/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/learn-web/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["PlaygroundProvider"] */ "/learn-web/packages/ui/lib/components/Playgroundv2/PlaygroundProvider.tsx");
