'use client'

import type { FC, PropsWithChildren } from 'react'

import {
  AppProgressProvider as BProgressProvider,
  Progress,
  Bar,
} from '@bprogress/next'

export const ProgressProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {/* Setting template to null disables the default template so we can customize the progress bar appearance */}
      {/*Link to documentation https://bprogress.vercel.app/docs/react/components/progress-components/progress#import */}
      <BProgressProvider options={{ template: null }}>
        <Progress>
          <Bar className="h-0.5 bg-blue-400" />
        </Progress>
      </BProgressProvider>
      {children}
    </>
  )
}
